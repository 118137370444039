export const StorageKey = {
  ACCES_TOKEN: 'ACCES_TOKEN',
  COGNITO_USER: 'COGNITO_USER'
};

export const Url = {
  REGISTER: process.env.REACT_APP_CONTACT_REGISTER_URL,
  LEGAL: process.env.REACT_APP_CONTACT_LEGAL_NOTICE_URL,
  HOME: process.env.REACT_APP_CONNECT_URL,
  DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN
};

export const Email = {
  CONTACT: process.env.REACT_APP_CONTACT_MAIL
};
