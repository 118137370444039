import React from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, withRouter } from 'react-router-dom';
import { setCognitoUser } from '../../actions/userAction';
import ForgotPassword from '../password/forgotPassword';
import { getRedirectUrl } from '../../tools/utils';
import ChangePassword from '../password/changePassword';
import Primary from '../buttons/primary';

const HomeContainer = styled.div`
  min-height: 100vh;
  margin-top: -88px;
  padding-top: 88px;
  padding-bottom: 100px;

  .password {
    position: relative;

    &__input {
    }

    &__show {
      cursor: pointer;
      position: absolute;
      right: 30px;
      top: 47px;
    }
  }

  .rectangle {
    max-width: 655.8px;
    min-height: 472.5px;
    margin: 31px auto;
    padding: 45px 90px 45px;
    border-radius: 20px;
    box-shadow: 0 8px 14px 0 #e5e5e5;
    background-color: #ffffff;
  }

  .input {
    width: 90%;
    min-width: 140px;
    margin-top: 30px;
    padding: 10px;
  }

  .forgot {
    width: 85%;
    display: block;
    margin: 10px auto 0;
    font-size: 15px;
    text-align: left;
    color: var(--warm-grey);
    cursor: pointer;
  }

  p.signup {
    margin: 31px auto 110.5px;
    font-size: 20px;
  }

  a.signup {
    color: var(--tomato);
  }

  .error {
    color: var(--tomato);
    text-align: left;
    margin-left: 20px;
    font-weight: bold;
    margin-bottom: -25px;
  }

  @media all and (max-width: 500px) {
    .rectangle {
      padding: 45px 20px 45px;
    }
  }
`;

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      type: 'password',
      error: '',
      cognitoUser: null,
      hasForgot: false,
      loading: false,
      modalShow: false
    };

    this.logout();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showHide = this.showHide.bind(this);
    this.signIn = this.signIn.bind(this);
    this.removeSpaces = this.removeSpaces.bind(this);
  }

  logout() {
    Auth.signOut()
      .then(() => localStorage.clear())
      .catch((err) => console.log(err));
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  removeSpaces(event) {
    this.setState({ [event.target.name]: event.target.value.replace(/\s/g, '') });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: '' });
    if (this.state.email !== '' && this.state.password !== '') {
      this.setState({ loading: true });
      this.signIn();
    } else {
      this.setState({ error: 'Merci de renseigner tous les champs' });
    }
  }

  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input'
    });
  }

  async signIn() {
    try {
      try {
        Auth.signOut(); // to avoid 413 error
      } catch {
        // user is not authenticated
      }
      const user = await Auth.signIn(this.state.email, this.state.password);
      if (user) {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.setState({ cognitoUser: user });
        } else {
          let redirectUrl = await getRedirectUrl(user);
          const params = new URLSearchParams(this.props.location.search);
          const idToValidate = params.get('idToValidate');
          if (idToValidate) redirectUrl += '&idToValidate=' + idToValidate;
          this.setState({ loading: false });
          window.location.href = redirectUrl;
        }
      }
    } catch (error) {
      this.setState({ loading: false });
      if (error.code === 'NotAuthorizedException' || error.code === 'UserNotFoundException') {
        this.setState({ error: <div className="error">Identifiants invalides</div> });
      } else {
        console.log('error signing in', error);
      }
    }
  }

  render() {
    let icon;
    if (this.state.type === 'password') {
      icon = <FaEye />;
    } else {
      icon = <FaEyeSlash />;
    }
    if (this.state.cognitoUser !== null) {
      return <ChangePassword user={this.state.cognitoUser} />;
    }
    if (this.state.hasForgot) {
      return (
        <div>
          <ChangePassword email={this.state.email} />
          <ForgotPassword
            show={this.state.modalShow}
            onHide={() => this.setState({ modalShow: false })}
            email={this.state.email}
            onSubmit={(email) => this.setState({ hasForgot: true, email: email })}
            valid={true}
          />
        </div>
      );
    }
    return (
      <HomeContainer>
        <form className="rectangle">
          <h2>Participez à notre communauté !</h2>
          <div className="error">{this.state.error}</div>
          <input
            className="input"
            type="email"
            name="email"
            value={this.state.email}
            onChange={this.handleChange}
            placeholder="Email"
            onBlur={this.removeSpaces}
          />
          <br />
          <div className="password">
            <input
              className="password__input input"
              type={this.state.type}
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
              placeholder="Mot de passe"
              onBlur={this.removeSpaces}
            />
            <span className="password__show" onClick={this.showHide}>
              {icon}
            </span>
          </div>
          <p onClick={() => this.setState({ modalShow: true })} className="forgot">
            Mot de passe oublié ?
          </p>
          <Primary
            text="Se connecter"
            onButtonClick={this.handleSubmit}
            spinner={this.state.loading}
            disabled={this.state.loading}
            style={{ width: '90%', fontSize: '20px', margin: '20px 0' }}
          />
        </form>
        <p className="signup">
          Pas encore de compte Breeder Connect&apos; ?{' '}
          <Link className="signup" to="/register">
            S&apos;inscrire
          </Link>
        </p>

        <ForgotPassword
          show={this.state.modalShow}
          onHide={() => this.setState({ modalShow: false })}
          email={this.state.email}
          onSubmit={(email) => this.setState({ hasForgot: true, email: email })}
          valid={false}
        />
      </HomeContainer>
    );
  }
}

const mapDispatchToProps = {
  setCognitoUser
};

Home.propTypes = {
  location: PropTypes.object
};

export default connect(null, mapDispatchToProps)(withRouter(Home));
