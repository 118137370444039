import React from 'react';
import Footer from './app/components/home/footer';
import Home from './app/components/home/home';
import Header from './app/components/home/header';
import { Switch, Route } from 'react-router-dom';
import './app/styles/globals.css';
import './App.css';
import { Url } from './app/components/constants/appConstants';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure({ ...awsconfig, Auth: { cookieStorage: { domain: Url.DOMAIN ?? 'localhost' } } });

function App() {
  return (
    <div className="App">
      <Header />
      <Switch>
        <Route
          path="/register"
          component={() => {
            window.location.href = Url.REGISTER;
            return null;
          }}
          exact
        />
        <Route
          path="/home"
          component={() => {
            window.location.href = Url.HOME;
            return null;
          }}
          exact
        />
        <Route path="/" component={Home} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
