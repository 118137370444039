import { SET_COGNITO_USER } from '../actions/action-type';

const initialState = {
  cognitoUser: ''
};

export default function (state = initialState, action) {
  if (action.type === SET_COGNITO_USER) {
    return Object.assign({}, state, {
      cognitoUser: action.payload
    });
  }

  return state;
}
