import styled from 'styled-components';
import { Url } from '../constants/appConstants';

const FooterContainer = styled.footer`
  height: 215px;
  background-color: #f4f4f4;
  margin-top: -168px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: absolute;
  .header__logo {
    width: 192px;
    height: 82px;
    object-fit: contain;
    margin-bottom: 24px;
  }
  a:hover {
    color: #4e5fb7;
    text-decoration: none;
  }
  .footImg {
    position: relative;
    top: -22px;
  }
  .small {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #5d6267;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <img className="footImg" src="/footer-connect-white.svg" alt="img" />
      <img className="header__logo" src="/logo.svg" alt="logo" />
      <div className="small">
        <a target="_blank" href={Url.HOME + '/legal-notice'} rel="noreferrer">
          Mentions légales
        </a>{' '}
        - 2021 - <a href="https://prod.breeder-connect.io/">breeder-connect.io</a>
      </div>
    </FooterContainer>
  );
};

export default Footer;
