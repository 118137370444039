import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { getRedirectUrl } from '../../tools/utils';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Primary from '../buttons/primary';

const PasswordContainer = styled.div`
  min-height: 100vh;
  margin-top: -88px;
  padding-top: 88px;
  padding-bottom: 100px;

  .rectangle {
    max-width: 655.8px;
    min-height: 360px;
    margin: 31px auto;
    padding: 48.8px 90px 54px;
    border-radius: 20px;
    box-shadow: 0 8px 14px 0 #e5e5e5;
    background-color: #ffffff;
  }

  .input {
    width: 100%;
    min-width: 140px;
    margin-top: 30px;
    margin-bottom: 0;
    padding: 10px;
  }

  .password {
    position: relative;

    &__input {
    }

    &__show {
      cursor: pointer;
      position: absolute;
      right: 30px;
      top: 47px;
    }
  }

  .validation {
    max-width: 748px;
    height: 222px;
    margin: 20px auto 105px;
    padding: 20.1px 24px 29.9px 29px;
    border-radius: 20px;
    box-shadow: 0 8px 14px 0 #e5e5e5;
    background-color: var(--pale-blue);

    p,
    .control {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.2;
      text-align: left;
      color: #838383;
    }
  }

  .error {
    text-align: left;
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: -22px;
    font-size: 12px;
    line-height: 1.2;
    color: var(--red);
  }

  .alert {
    margin: 15px auto -65px;
    max-width: 750px;
  }

  .icon {
    width: 11.3px;
    height: 11.3px;
    margin-right: 3px;
  }

  @media all and (max-width: 650px) {
    .rectangle {
      padding: 10% 5%;
    }
  }
`;

const ChangePassword = ({ user, email = null }) => {
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [confirm, setConfirm] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState('');

  const [minChar, setMinChar] = useState(false);
  const [number, setNumber] = useState(false);
  const [special, setSpecial] = useState(false);
  const [maj, setMaj] = useState(false);
  const [min, setMin] = useState(false);
  const [type, setType] = useState('password');
  const [confType, setConfType] = useState('password');

  function handlePasswordChange(event) {
    const hasNumber = /\d/;
    const hasMaj = /[A-Z]/;
    const hasMin = /[a-z]/;
    const hasSpecial = /[$&+,:;=?@#|'<>.^*()%!-]/;
    setPassword(event.target.value);

    if (hasNumber.test(event.target.value)) {
      setNumber(true);
    } else {
      setNumber(false);
    }

    if (event.target.value.length >= 8) {
      setMinChar(true);
    } else {
      setMinChar(false);
    }

    if (hasMaj.test(event.target.value)) {
      setMaj(true);
    } else {
      setMaj(false);
    }

    if (hasMin.test(event.target.value)) {
      setMin(true);
    } else {
      setMin(false);
    }

    if (hasSpecial.test(event.target.value)) {
      setSpecial(true);
    } else {
      setSpecial(false);
    }
  }

  function handleConfirmChange(event) {
    setConfirm(event.target.value);
  }

  function handleCodeChange(event) {
    setCode(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (minChar && number && special && maj && min) {
      if (!email && confirm !== password) {
        setError(
          <div className="error">La confirmation de votre mot de passe ne correspond pas au mot de passe saisi</div>
        );
      } else {
        setLoading(true);
        changePassword();
      }
    }
  }

  function changePassword() {
    let currentUser = {};
    Auth.currentAuthenticatedUser()
      .then((cogUser) => (currentUser = cogUser))
      .catch(() => (currentUser = user))
      .finally(() => {
        if (!email) {
          Auth.completeNewPassword(currentUser, password)
            .then((result) => {
              setAlert(<Alert variant="success">Votre mot de passe a bien été changé. Vous allez être redirigé</Alert>);
              getRedirectUrl(result).then((redirectUrl) => {
                setLoading(false);
                setTimeout(() => {
                  window.location.href = redirectUrl;
                }, 4000);
              });
            })
            .catch((err) => {
              setAlert(<Alert variant="danger">Un problème est survenu. Merci de réessayer plus tard</Alert>);
              setLoading(false);
              console.log(err);
            });
        } else {
          Auth.forgotPasswordSubmit(email, code, password)
            .then(() => {
              setAlert(
                <Alert variant="success">
                  Votre mot de passe a bien été changé. Vous allez être redirigé vers l&apos;écran de connexion
                </Alert>
              );
              setLoading(false);
              setTimeout(() => {
                window.location.reload();
              }, 4000);
            })
            .catch((err) => {
              if (err.code === 'CodeMismatchException')
                setAlert(<Alert variant="danger">Le code de vérification est erroné</Alert>);
              else setAlert(<Alert variant="danger">Un problème est survenu. Merci de réessayer plus tard</Alert>);
              setLoading(false);
              console.log(err);
            });
        }
      });
  }

  function showHide(e, isPassword) {
    e.preventDefault();
    e.stopPropagation();
    console.log(e.target);
    isPassword
      ? setType(type === 'input' ? 'password' : 'input')
      : setConfType(confType === 'input' ? 'password' : 'input');
  }

  return (
    <PasswordContainer>
      {alert}
      <form className="rectangle">
        <h2>Réinitialiser votre mot de passe</h2>
        <div className="password" hidden={!email}>
          <input
            className="password__input input"
            type="text"
            name="code"
            value={code}
            onChange={handleCodeChange}
            placeholder="Code de vérification"
            autoComplete="off"
          />
        </div>
        <div className="password">
          <input
            className="password__input input"
            type={type}
            name="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Mon nouveau mot de passe"
            autoComplete="off"
          />
          <span className="password__show" id="password" onClick={(e) => showHide(e, true)}>
            {type === 'password' ? <FaEye /> : <FaEyeSlash />}
          </span>
        </div>
        <div className="password" hidden={email}>
          <input
            className="password__input input"
            type={confType}
            name="confirm"
            value={confirm}
            onChange={handleConfirmChange}
            placeholder="Confirmer mon nouveau mot de passe"
            autoComplete="off"
          />
          <span className="password__show" id="confirm" onClick={(e) => showHide(e, false)}>
            {confType === 'password' ? <FaEye /> : <FaEyeSlash />}
          </span>
        </div>
        {error}
        <Primary
          text="Valider"
          onButtonClick={handleSubmit}
          disabled={loading}
          spinner={loading}
          style={{ width: '100%', marginLeft: '0', marginTop: '40px', marginBottom: '0' }}
        />
      </form>
      <div className="validation">
        <p>Pour rendre votre mot de passe plus sûr, il doit comporter :</p>
        <p>
          {minChar ? (
            <img className="icon" src="./icons/check.png" alt="check" />
          ) : (
            <img className="icon" src="./icons/wrong.png" alt="wrong" />
          )}
          au minimum 8 caractères
          <br />
          {number ? (
            <img className="icon" src="./icons/check.png" alt="check" />
          ) : (
            <img className="icon" src="./icons/wrong.png" alt="wrong" />
          )}
          au moins un chiffre
          <br />
          {special ? (
            <img className="icon" src="./icons/check.png" alt="check" />
          ) : (
            <img className="icon" src="./icons/wrong.png" alt="wrong" />
          )}
          au moins un caractère spécial
          <br />
          {maj ? (
            <img className="icon" src="./icons/check.png" alt="check" />
          ) : (
            <img className="icon" src="./icons/wrong.png" alt="wrong" />
          )}
          au moins une majuscule
          <br />
          {min ? (
            <img className="icon" src="./icons/check.png" alt="check" />
          ) : (
            <img className="icon" src="./icons/wrong.png" alt="wrong" />
          )}
          au moins une minuscule
          <br />
        </p>
        <p>
          Nous vous préconisons de créer un nouveau mot de passe, sans réutiliser l&apos;ancien, et de vous en servir
          uniquement sur votre compte Breeder Connect&apos;
        </p>
      </div>
    </PasswordContainer>
  );
};

ChangePassword.propTypes = {
  email: PropTypes.string,
  user: PropTypes.object
};

export default ChangePassword;
