import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';

const BtnContainer = styled(Button)`
  width: 100%;
  min-height: 48px;
  border-radius: 8px;
  background-image: linear-gradient(to bottom, #ee8b0d, #e49a3b);
  margin: 20px;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
  border: none;
  .spinner-border {
    margin-left: 10px;
    margin-bottom: 3px;
  }
`;

const Primary = ({ onButtonClick, text, style, disabled = false, spinner = false }) => {
  return (
    <BtnContainer size="lg" onClick={onButtonClick} style={style} disabled={disabled}>
      {text}
      {spinner ? (
        <Spinner size="sm" as="span" animation="border" role="status" aria-hidden="true" variant="warning" />
      ) : (
        ''
      )}
    </BtnContainer>
  );
};

Primary.propTypes = {
  onButtonClick: PropTypes.func,
  text: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  spinner: PropTypes.bool
};

export default Primary;
