import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeaderContainer = styled.header`
  background-color: white;
  position: sticky;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20.3px 0 89.2px;
  .header__logo {
    width: 145.2px;
    height: 54.6px;
    object-fit: contain;
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <Link to="/home">
        <img className="header__logo" src="/logo.svg" alt="logo" />
      </Link>
    </HeaderContainer>
  );
};

export default Header;
