import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { Modal, Alert } from 'react-bootstrap';
import styled from 'styled-components';
import Primary from '../buttons/primary';

const StyleModal = styled(Modal)`
  .modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
  }

  input {
    width: 60%;
    color: #000000 !important;
  }

  .modal-content {
    border-radius: 10px;
    padding: 8px;
  }

  .modal-header,
  .modal-footer {
    border: none;
  }

  .modal-body {
    padding-bottom: 0;
  }

  .btnValidate:disabled {
    opacity: 0.65;
  }

  .btnContainer {
    width: 30%;
    margin: 20px 0px;
  }

  .modal-title {
    font-weight: bold;
    margin-left: 8px;
  }

  .close {
    width: 30px;
    height: 30px;
    margin: 0 0 12px 132px;
    padding: 8px;
    border-radius: 4px;
    background-color: #4e5fb7;
    color: white;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: none;
    font-weight: normal;
    span {
      margin-top: -5px;
    }
  }

  @media all and (max-width: 991px) {
    .input {
      width: 100%;
    }
    .btnContainer {
      width: 100%;
    }
  }
`;

const ForgotPassword = (props) => {
  const [email, setEmail] = useState(props.email);
  const [alert, setAlert] = useState('');
  const [loading, setLoading] = useState(false);

  function handleChange(event) {
    setEmail(event.target.value);
  }

  function removeSpaces(event) {
    setEmail(event.target.value.replace(/\s/g, ''));
  }

  useEffect(() => {
    setEmail(props.email);
  }, [props.email]);

  function handleSubmit() {
    setLoading(true);
    Auth.forgotPassword(email)
      .then((data) => {
        console.log(data);
        setLoading(false);
        props.onSubmit(email);
      })
      .catch((err) => {
        console.log(err);
        setAlert(<Alert variant="danger">Une erreur est survenue.</Alert>);
        setLoading(false);
      });
  }

  const prop = props.valid ? { size: 'lg' } : '';

  return (
    <StyleModal {...props} {...prop} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Mot de passe oublié ?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alert}
        {!props.valid ? (
          <p>Pour réinitialiser votre mot de passe, saisissez l’adresse mail que vous utilisez pour vous connecter</p>
        ) : (
          <p>
            Un email vous a été envoyé à <strong>{email}</strong> si vous possédez un compte avec cette adresse.
            <br />
            Si vous ne le recevez pas, vérifiez qu&apos;il n&apos;a pas été bloqué par votre anti-spams.
          </p>
        )}
      </Modal.Body>
      <Modal.Footer hidden={props.valid}>
        <input
          className="input"
          type="text"
          value={email}
          onChange={handleChange}
          placeholder="Email"
          onBlur={removeSpaces}
        />
        <div className="btnContainer">
          <Primary
            text="Valider"
            onButtonClick={handleSubmit}
            disabled={loading}
            spinner={loading}
            style={{ margin: '0' }}
          />
        </div>
      </Modal.Footer>
    </StyleModal>
  );
};

ForgotPassword.propTypes = {
  email: PropTypes.string,
  onSubmit: PropTypes.func,
  onHide: PropTypes.func,
  valid: PropTypes.bool
};

export default ForgotPassword;
